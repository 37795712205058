import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { IconShare2 } from "@tabler/icons-react";
import { classNames, useAppConfig } from "@ct-react/core";
import { FormattedBookingOption, isBookingPrice } from "@ct-react/calendar";
import { useLocaleFormatter } from "@ct-react/locale";
import { CustomViewType } from "@shared/models/app-context";
import { SkeletonableProps } from "../../../tools/components";
import { bookingTranslations, globalTranslations } from "../../../i18n/sharable-defs";
import { Image } from "../../../models/images";
import Modal, { InnerModal } from "../../common/modal";
import Sharing from "../sharing";
import "./title.scss";

const Star = ({ index, fill = 0 }: { index: number, fill?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       className="eval-star"
       width="24" height="24" viewBox="0 0 24 24"
       strokeWidth="0" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <defs>
      <linearGradient id={`progress-${index}`} x1="0" x2="1" y1="0" y2="0">
        <stop offset={fill.toFixed(2)} stopColor="var(--filling-color)" />
        <stop offset={fill.toFixed(2)} stopColor="var(--bg-color)" />
      </linearGradient>
    </defs>
    <path fill={`url(#progress-${index})`}
          d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" />
  </svg>
);

const transDefs = defineMessages({
  noteTitle: { id: "booking-reviews-title", defaultMessage: "Évaluations"},
  priceDetailPerson: { id: "booking-detail-price-persons", defaultMessage: "{display, select, true { et {count, plural, =1 {une personne} other {# personnes}}} other {}}" },
  priceDetail: { id: "booking-detail-price", defaultMessage: "{price} pour {nights}{person}" },
  priceAverage: { id: "booking-average-price", defaultMessage: "Dès {val} par semaine{person}" },
  priceDailyAverage: { id: "booking-daily-average-price", defaultMessage: "Dès {val} par jour{person}" },
  modalShareTitle: { id: "sharing-title", defaultMessage: "Partager ce logement" }
});

interface TitleData {
  title: string;
  thumbnail: Image;
  address: string;
  rate?: number;
  lowestPrice?: { onDemand: boolean, type: string, value: string }
  bookingSelection?: FormattedBookingOption;
}

type TitleProps = SkeletonableProps<TitleData> & {
  onRateClick?: () => void
};

const Title = (
  {
    loading,
    className,
    data,
    onRateClick = () => void 0
  }: TitleProps) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();
  const { options: { customView } } = useAppConfig();

  const pricePersonOpt = useMemo(() => ({
    display: customView === CustomViewType.HOTEL,
    count: 2
  }), [ customView ]);

  const wrapperClasses = classNames("rla-title", className);

  if (loading) return (
    <div className={wrapperClasses}>
      <div className="rate">
        <Skeleton className="label" />
        <span className="value">
          {Array.from({ length: 5 }).map((_, i) => <Star key={i} index={i} />)}
        </span>
      </div>
      <div className="title-data">
        <h1><Skeleton/></h1>
        <span className="address"><Skeleton width="50%" /></span>
        <span className="average-price"><Skeleton width="100%" /></span>
      </div>
    </div>);

  return (
    <div className={wrapperClasses}>

      {!!data!.rate &&
        <div className="rate scroll" onClick={onRateClick}>
          <span className="label active">{intl.formatMessage(transDefs.noteTitle)}</span>
          <span className="value">
            {Array.from({ length: 5 }).map((_, i) => {
              const fill = data!.rate! / (i + 1) >= 1 ? 1 : (data!.rate! - i) % 1;
              return <Star key={i} index={i} fill={fill} />;
            })}
          </span>
        </div>
      }

      <div className="title-data">

        <h1>{data!.title}</h1>
        <span className="address">{data!.address}</span>

        <span className="average-price">
          {(() => {
            if (isBookingPrice(data?.bookingSelection?.price)) {
              return intl.formatMessage(transDefs.priceDetail, {
                price: <strong>{print.price(data!.bookingSelection!.price.amount)}</strong>,
                nights: intl.formatMessage(bookingTranslations.nightsPeriod, {
                  nightsCount: data!.bookingSelection!.period.end.diff(data!.bookingSelection!.period.start, "day")
                }),
                person: intl.formatMessage(transDefs.priceDetailPerson, pricePersonOpt)
              });
            } else if (!!data!.lowestPrice?.value && !data!.bookingSelection) {
              return intl.formatMessage(data!.lowestPrice?.type === "DAILY" ? transDefs.priceDailyAverage : transDefs.priceAverage, {
                val: <strong>{print.price(data!.lowestPrice!.value)}</strong>,
                person: intl.formatMessage(transDefs.priceDetailPerson, pricePersonOpt)
              });
            } else {
              return <strong>{intl.formatMessage(bookingTranslations.fullPriceOnDemand)}</strong>;
            }
          })()}
        </span>

        <div className="sharing">
          <Modal size="sm">

            <InnerModal.Trigger as="button" className="r-btn link inline-icon bolder">
              <IconShare2/>
              <span className="label">{intl.formatMessage(globalTranslations.share)}</span>
            </InnerModal.Trigger>

            <InnerModal.Content title={intl.formatMessage(transDefs.modalShareTitle)}>
              <Sharing title={data!.title}
                       address={data!.address}
                       thumbnail={data!.thumbnail} />
            </InnerModal.Content>

          </Modal>
        </div>

      </div>

    </div>
  );

}

export default Title;
