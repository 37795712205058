import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormDefinition, FormInputConfigDefinition, FormInputDefinition, FormState, ValueType } from "../models/form";
import { useIsMounted } from "@ct-react/core";

type ValidityState = {  touched: boolean; valid: boolean; }

const validatorChecker = (def: FormInputDefinition, val?: ValueType): boolean => {
  if (!def) return true;
  if (typeof def === "boolean") return !val || typeof val === "boolean";
  if (typeof def === "number") return !val || typeof val === "number";
  if (typeof def === "string") return !val || typeof val === "string";
  const typedDef = def as FormInputConfigDefinition;
  return !!typedDef.validator ? typedDef.validator(val) : true;
}

const initValues = (formDef: FormDefinition): Record<string, ValueType> => {
  const result = Object.entries(formDef).map(([ key, def ]) => {
    if ([ "string", "number", "boolean" ].includes(typeof def)) return [ key, def ];
    if (!!def && typeof def === "object") return [ key, def.defaultValue ];
    return [ key, null ];
  });
  return Object.fromEntries(result);
}

const refreshedValidities = (defs: FormDefinition, values: Record<string, ValueType>, touch?: boolean): Record<string, ValidityState> => {
  const results = Object.entries(defs).map(([ key, def ]) => {
    const valid = validatorChecker(def, values[key]);
    return [ key, { touched: !!touch, valid }];
  });
  return Object.fromEntries(results);
}

export const useFormValidator = (formDef: FormDefinition, initialTouch?: boolean) => {

  const isMounted = useIsMounted();
  const definition = useRef<FormDefinition>(formDef);

  const initedValues = initValues(formDef);
  const [ validities, setValidities ] = useState<Record<string, ValidityState>>(refreshedValidities(definition.current, initedValues, initialTouch));
  const [ values, setValues ] = useState<Record<string, ValueType>>(initedValues);

  useEffect(() => {
    if (!isMounted) return;
    definition.current = formDef;
    const refreshValues = initValues(formDef);
    setValidities(refreshedValidities(formDef, refreshValues, initialTouch));
    setValues(refreshValues);
  }, [ formDef ]);

  const formState = useMemo((): FormState => {
    return Object.fromEntries(Object
      .entries(values)
      .map(([ key, value ]) => ([ key, { value, dirty: validities[key].touched && !validities[key].valid } ])))
  }, [ validities ]);

  const isValid = useMemo(() => Object.values(validities).every(v => v.valid), [ validities, formState ]);

  const onInputChange = (key: string, value: any) => {
    if (!Object.keys(definition.current).includes(key)) return;
    setValues({ ...values, [key]: value });
    setValidities({ ...validities, [key]: { touched: true, valid: validatorChecker(definition.current[key], value) } });
  }

  const onInputBlur = (key: string, value: any) => {
    if (!Object.keys(definition.current).includes(key)) return;
    setValidities({ ...validities, [key]: { touched: true, valid: validatorChecker(definition.current[key], value) } });
  }

  const formPurge = useCallback((value?: Record<string, ValueType>) => {
    const refreshValues = value ?? initValues(formDef);
    setValidities(refreshedValidities(formDef, refreshValues, initialTouch));
    setValues(refreshValues);
  }, [ formDef ]);

  return {
    formData: values,
    formState,
    isValid,
    onInputChange,
    onInputBlur,
    formPurge
  };

}
