import React, { useMemo } from "react";
import { classNames } from "@ct-react/core";
import { Asset, Ratio } from "../../models/images";
import "./ratio-images.scss";

type RatioImageProps = {
  className?: string;
  ratio?: Ratio;
  images: Asset[];
  alt: string;
}

const RatioImage = (
  {
    className,
    ratio = Ratio.THREE_TWO,
    images,
    alt
  }: RatioImageProps) => {

  const sm = useMemo(() => images.find(i => i.breakpoint === "SM"), [ images ]);
  const md = useMemo(() => images.find(i => i.breakpoint === "MD"), [ images ]);
  const lg = useMemo(() => images.find(i => i.breakpoint === "LG"), [ images ]);
  const full = useMemo(() => images.find(i => !i.breakpoint), [ images ]);

  const wrapperClasses = classNames("r-img", ratio, className);

  return (
    <picture className={wrapperClasses}>
      {!!sm && <source srcSet={sm.url} media="(max-width: 375px) "/>}
      {!!md && <source srcSet={md.url} media="(max-width: 992px) "/>}
      <img src={lg?.url || full?.url || md?.url || sm?.url} alt={alt} />
    </picture>
  );

};

export default RatioImage;
