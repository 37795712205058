import React from "react";
import { useLocaleFormatter } from "@ct-react/locale";
import { ResumeBookChoice } from "../../../models/article";
import { Ratio } from "../../../models/images";
import RatioImage from "../../common/ratio-image";
import "./book-choice-resume.scss";

const BookChoiceResume = (props: ResumeBookChoice) => {

  const { print } = useLocaleFormatter();

  return (
    <div className="rla-choice-resume">
      {!!props.thumbnail &&
        <RatioImage images={props.thumbnail.assets} alt={props.title} ratio={Ratio.SQUARE} />
      }
      <div className="metas">
        <span className="title">{props.title}</span>
        <span className="location">{props.address}</span>
        {!!props.bookingSelection &&
          <span className="period">{print.period(props.bookingSelection.period, true)}</span>
        }
      </div>
    </div>);

}

export default BookChoiceResume;
