import React, { Component, ErrorInfo, PropsWithChildren } from "react";
import ReactGA from "react-ga4";
import { defineMessages, useIntl } from "react-intl";
import { IconBug, IconChevronsRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLocaleUrl } from "@ct-react/locale";
import Wrapper from "./wrapper";
import "./error-boundary.scss";

const transDefs = defineMessages({
  title: { id: "running-error-title", defaultMessage: "Oups... On dirait que quelque chose c'est mal passé." },
  link: { id: "running-error-link",  defaultMessage: "Veuillez actualiser la page et réessayer" }
});

type ErrorState = { hasError: boolean };

export const RunningErrorUi = () => {

  const intl = useIntl();
  const { currentUrl } = useLocaleUrl();

  return (
    <div className="rl-running-error">
      <Wrapper.Centered as="main">
        <IconBug/>
        <div className="error-refresh">
          <h1>{intl.formatMessage(transDefs.title)}</h1>
          <div className="link">
            {intl.formatMessage(transDefs.link)}
            <Link to={currentUrl}>
              <IconChevronsRight />
            </Link>
          </div>
        </div>
      </Wrapper.Centered>
    </div>)

}

export class ErrorBoundary extends Component<PropsWithChildren, ErrorState> {

  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ReactGA.event("react_running_error", {
      "error_message": error.message,
      "source_component":errorInfo.componentStack
    });
  }

  render = () => {
    if (this.state.hasError)
      return (<RunningErrorUi />);

    return this.props.children;
  }

}
