import React, { forwardRef, MouseEvent, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { IconChevronLeft, IconChevronRight, IconPhoto, IconX } from "@tabler/icons-react";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { classNames } from "@ct-react/core";
import { SkeletonableProps } from "../../../tools/components";
import { Image, Ratio } from "../../../models/images";
import RatioImage from "../../common/ratio-image";
import { Button } from "../../common/minimals";
import "./images.scss";

type ImagesProps = SkeletonableProps<Image[]>;
type FullscreenProps = { data?: Image[] };
type FullscreenImagesHandle = {
  open: (index?: number) => void;
}

const FullscreenImages = forwardRef<FullscreenImagesHandle, FullscreenProps>(({ data = [] }, forwardedRef) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ images ] = useState(data.map(i => ({
    src: i.assets[0].url,
    loading: "lazy",
    alt: ""
  })) as ImagesListType);

  useImperativeHandle(forwardedRef, () => ({
    open: (index?: number) => {
      if (typeof index !== "undefined") setCurrentIndex(index);
      setIsOpen(true);
    }
  }));

  const onPrev = () => currentIndex > 0 && setCurrentIndex(currentIndex - 1);
  const onNext = () => currentIndex + 1 < images.length && setCurrentIndex(currentIndex + 1);
  const onClose = () => setIsOpen(false);

  const headerRendering = useMemo(() =>
    <div className="rli-head">
      <span className="rli-state">{currentIndex + 1} / {images.length}</span>
      <Button type="button"
              className="rli-close rounded-icon link"
              onClick={() => setIsOpen(false)}>
        <IconX />
      </Button>
    </div>, [ currentIndex ]);

  const prevRendering = useMemo(() =>
    <Button type="button"
            className="rli-nav rounded-icon link"
            onClick={onPrev}
            disabled={currentIndex === 0}>
      <IconChevronLeft />
    </Button>, [ currentIndex ]);

  const nextRendering = useMemo(() =>
    <Button type="button"
            className="rli-nav rounded-icon link"
            onClick={onNext}
            disabled={currentIndex + 1 === images.length}>
      <IconChevronRight />
    </Button>, [ currentIndex ]);

  return (<Lightbox className="rli-fullscreen"
                    {...{ isOpen, currentIndex, images, onPrev, onNext, onClose }}
                    renderHeader={() => headerRendering}
                    renderPrevButton={() => prevRendering}
                    renderNextButton={() => nextRendering} />);

});

const Images = (
  {
    loading,
    className,
    data
  }: ImagesProps) => {

  const intl = useIntl();
  const fullscreenRef = useRef<FullscreenImagesHandle>(null);
  const wrapperClasses = classNames("rla-images", className);

  const onMore = (e: MouseEvent<HTMLElement>) => {
    fullscreenRef.current?.open();
    e.stopPropagation();
  }

  if (loading)
    return (
      <Skeleton containerClassName={wrapperClasses}
                className="w-img r-img ratio-16-9"
                inline={true}
                count={3} />);

  return (
    <>
      <div className={classNames("rla-images", className)}>
        {data!.slice(0, 3).map((image, i) =>
          <div key={i}
               className="w-img"
               onClick={() => fullscreenRef.current?.open(i)}>
            <RatioImage ratio={Ratio.SIXTEEN_NINE} images={image.assets} alt="" />
            {i === 2 &&
              <button type="button"
                      className="more"
                      onClick={onMore}>
                <IconPhoto />
                {intl.formatMessage({
                  id: "booking-images-all-button",
                  defaultMessage: "Voir tout"
                })}
              </button>
            }
          </div>
        )}
      </div>
      <FullscreenImages ref={fullscreenRef}
                        data={data} />
    </>);

}

export default Images;
