import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { IconCalendar, IconChevronLeft, IconChevronRight, IconDiscount2 } from "@tabler/icons-react";
import { useFloating, useHover, useInteractions } from "@floating-ui/react";
import { AnyPeriod, classNames, debounce, momentizePeriod, toGQLPeriod, useIsMounted } from "@ct-react/core";
import { LocaleLink, useLocaleContext, useLocaleFormatter, useLocaleNavigate } from "@ct-react/locale";
import { FormattedBookingOption, isDiscountedPrice } from "@ct-react/calendar";
import { buildArticleUrl } from "@shared/urls";
import { bookingTranslations } from "../../i18n/sharable-defs";
import { DataProps } from "../../tools/components";
import { Ratio } from "../../models/images";
import { ArticleSummary, Discount } from "../../models/article";
import RatioImage from "../common/ratio-image";
import ResumeFeatures from "./resume-features";
import "./summary.scss";

type SummaryProps = ArticleSummary & {
  bookingSuggestion?: FormattedBookingOption;
  ratio?: Ratio;
  view?: "card" | "map";
  fixedDiscountView?: boolean;
}

type DisplayDiscountProps = DataProps<Discount[]> & {
  fixed?: boolean;
  onClick: (period: AnyPeriod) => void;
}

const DisplayDiscount = (
  {
    data,
    fixed = false,
    onClick
  }: DisplayDiscountProps) => {

  const intl = useIntl();
  const { locale } = useLocaleContext();
  const { print } = useLocaleFormatter();

  const [ visible, setVisible ] = useState(fixed);

  // floating

  const { refs, context } = useFloating({
    open: visible,
    ...(!fixed) && { onOpenChange: setVisible }
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { move: false })
  ]);

  const priceIntlValues = useCallback((discount: Discount) => ({
    val: (
      <span className="value">
        {discount.type === "FIXED" ? print.price(discount.fixedValue) : discount.percentageValue * 100 + "%"}
      </span>
    )
  }), [ locale ])

  const periodIntlValue = useCallback((period: AnyPeriod) => {
    const val = momentizePeriod(period);
    const isSameMonth = val.start.isSame(val.end, "month");
    return {
      start: intl.formatDate(val.start.toDate(), { day: "numeric", ...(!isSameMonth) && { month: "short" } }),
      end: intl.formatDate(val.end.toDate(), { day: "numeric", month: "short" })
    };
  }, [ locale ]);

  // dom interactions

  const blockEvent = (e: MouseEvent<HTMLDivElement>) => e.preventDefault();

  const onDiscountClick = (discount: Discount, e: MouseEvent<HTMLDivElement>) => {
    if (discount.logic === "FIXED") {
      e.preventDefault();
      onClick(discount.period);
    }
  }

  // rendering

  const wrapperClasses = classNames("discount-wrapper", { visible });

  return (
    <div ref={refs.setReference}
         {...getReferenceProps()}
         className={wrapperClasses}>
      <div className="discount-box"
           onClick={blockEvent}><IconDiscount2 /></div>
      {visible &&
        <div ref={refs.setFloating}
             {...getFloatingProps()}
             className="discounts-list">
          {data.slice(0, 3).map((discount, i) => (
            <div key={i}
                 className="one-discount"
                 onClick={onDiscountClick.bind(this, discount)}>
              <div className="value">
                {intl.formatMessage(bookingTranslations.discount, priceIntlValues(discount))}
              </div>
              <div className="period">
                {discount.logic === "FIXED"
                  ? <><IconCalendar />{print.period(discount.period)}</>
                  : <span>
                    {intl.formatMessage(bookingTranslations.discountPeriod, periodIntlValue(discount.period))}
                </span>
                }
              </div>
              {discount.logic === "FIXED" && <div className="targetable"><IconChevronRight /></div>
              }
            </div>)
          )}
        </div>
      }
    </div>);

};

const Summary = (
  {
    discounts = [],
    bookingSuggestion,
    ratio = Ratio.FULL_CARD,
    view = "card",
    fixedDiscountView = false,
    ...props
  }: SummaryProps) => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const navigate = useLocaleNavigate();
  const { print } = useLocaleFormatter();
  const isMapView = view === "map";

  // component refs

  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderTrackRef = useRef<HTMLDivElement>(null);
  const bulletsRef = useRef<HTMLDivElement>(null);
  const bulletTrackRef = useRef<HTMLUListElement>(null);
  const bulletRef = useRef<HTMLLIElement>(null);

  // component states

  const [ scrollIndex, setScrollIndex ] = useState(0);
  const [ scrollLength, setScrollLength ] = useState(props.images.length);

  useEffect(() => {
    if (!isMounted) return;
    setScrollIndex(0);
    setScrollLength(props.images.length);
  }, [ props.images ]);

  useEffect(() => {
    if (!isMounted) return;
    bulletTrackRef.current!.scroll({
      behavior: "smooth",
      left: Math.max(scrollIndex -2) * bulletRef.current!.scrollWidth
    })
  }, [ scrollIndex ]);

  const target = useMemo(() => {
    const url = buildArticleUrl(props);
    if (!bookingSuggestion)
      return url;
    const params = toGQLPeriod(bookingSuggestion.period);
    return `${url}&checkin=${params[0]}&checkout=${params[1]}`;
  }, [ props.id, bookingSuggestion ]);

  // dom interactions

  const onDiscountClick = (period: AnyPeriod) => {
    const params = toGQLPeriod(period);
    navigate(buildArticleUrl(props) + `&checkin=${params[0]}&checkout=${params[1]}`);
  }

  const onSliderScroll = debounce(() => {
    const newIndex = Math.round(sliderTrackRef.current!.scrollLeft / sliderRef.current!.scrollWidth);
    setScrollIndex(newIndex);
  }, 125);

  const onSliderNav = (e: MouseEvent, direction: -1 | 1) => {
    e.preventDefault();
    if ((direction === -1 && scrollIndex === 0) || (direction === 1 && scrollIndex + 1 === scrollLength))
      return;
    sliderTrackRef.current!.scroll({
      behavior: "smooth",
      left: sliderTrackRef.current!.scrollLeft + (direction * sliderRef.current!.scrollWidth)
    });
  }

  // rendering

  const wrapperClasses = classNames("rn-card", {"map-view": isMapView});
  const sliderTrackClasses = classNames("slider-track");
  const sliderBulletsClasses = classNames("slider-bullets");
  const suggestionClasses = classNames("book-date", { discounted: isDiscountedPrice(bookingSuggestion?.price) });

  return (
    <article className={wrapperClasses}>
      <LocaleLink to={target}>

        <div ref={sliderRef}
             className="slider">
          <div ref={sliderTrackRef}
               className={sliderTrackClasses}
               onScrollCapture={onSliderScroll}>
            {props.images.map((image, i) =>
              <RatioImage key={i}
                          className="slider-item"
                          images={image.assets}
                          alt={props.title.value}
                          ratio={ratio}/>
            )}
          </div>
          <button className={classNames("slider-arrow", "left", {mask: scrollIndex === 0})}
                  onClick={e => onSliderNav(e, -1)}>
            <IconChevronLeft/>
          </button>
          <button className={classNames("slider-arrow", "right", {mask: scrollIndex + 1 === scrollLength})}
                  onClick={e => onSliderNav(e, 1)}>
            <IconChevronRight/>
          </button>
        </div>

        <div className="resume">

          <div ref={bulletsRef}
               className={sliderBulletsClasses}>
            <ul ref={bulletTrackRef}
                className="slider-bullets-track">
              {props.images.map((_, i) =>
                <li key={i}
                    {...(i === 0) && {ref: bulletRef}}
                    className={classNames("slider-bullets-item", {active: i === scrollIndex})}/>)}
            </ul>
          </div>

          {!!bookingSuggestion &&
            <div className={suggestionClasses}>
              <IconCalendar/>
              {print.period(bookingSuggestion.period)}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {bookingSuggestion.price === "onDemand"
                ? intl.formatMessage(bookingTranslations.priceOnDemand)
                : print.price(bookingSuggestion.price.amount)
              }
            </div>
          }

          <h1>{props.title.value}</h1>
          {!isMapView && <ResumeFeatures {...props.features} />}

        </div>

        {(view === "card" && discounts.length > 0) &&
          <DisplayDiscount data={discounts}
                           fixed={fixedDiscountView}
                           onClick={onDiscountClick}/>
        }

      </LocaleLink>
    </article>);

}

export default Summary;
