import React, {useRef} from "react";
import {defineMessages, useIntl} from "react-intl";
import moment from "moment";
import {IconBrandFacebookFilled, IconBrandInstagram} from "@tabler/icons-react";
import {useAppConfig} from "@ct-react/core";
import {LocaleLink, useLocaleContext} from "@ct-react/locale";
import Wrapper from "./wrapper";
import { CookieBanner } from "../../code-splitting/client-only-ui";
import Logo from "../../assets/rentalp-logo.svg";
import summerBg from "../../assets/backgrounds/footer-bg-summer.webp";
import winterBg from "../../assets/backgrounds/footer-bg-winter.webp";
import "./footer.scss";
import { useSeason } from "../../hooks/generic";

const transDefs = defineMessages({
  copyright: { id: "copyright", defaultMessage: "tous droits réservés." },
  terms: { id: "legal-terms-link", defaultMessage: "dispositions contractuelles générales" },
  mentions: { id: "legal-mentions-link", defaultMessage: "mentions légales" },
  legal: { id: "legal-text", defaultMessage: "L'utilisation de ce site constitue l'acceptation des {terms} et des {mentions} de rentalp.com." }
});

const Footer = () => {

  const intl = useIntl();
  const { locale } = useLocaleContext();
  const { options: { asAgency } } = useAppConfig();
  const season = useSeason();
  const year = useRef<string>(`© ${moment().format("YYYY")}`);

  return (
    <Wrapper.Backgrounded as="footer"
                          className="rl-footer"
                          ratio={18}
                          imagePath={season === "winter" ? winterBg : summerBg}>
      {!asAgency &&
        <>
          <LocaleLink to="/">
            <Logo className="logo" />
          </LocaleLink>
          <ul className="socials">
            <li>
              <a href="https://www.instagram.com/rentalp.com_/" target="_blank">
                <IconBrandInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Rentalp.ch" target="_blank">
                <IconBrandFacebookFilled />
              </a>
            </li>
          </ul>
        </>
      }
      <span className="terms">
        {intl.formatMessage(transDefs.legal, {
          terms: <a href={`/assets/conditions/terms-${locale.basename}.pdf`} target="_blank">
            {intl.formatMessage(transDefs.terms)}
          </a>,
          mentions: <a href={`/assets/conditions/mentions-${locale.basename}.pdf`} target="_blank">
            {intl.formatMessage(transDefs.mentions)}
          </a>
        })}
      </span>
      <CookieBanner />
      <div className="copy">
        <span>{year.current}</span>
        <a href="https://www.creative-technologies.ch/" target="_blank">C.T. Creative Technologies SA</a>
        <span>{intl.formatMessage(transDefs.copyright)}</span>
      </div>
    </Wrapper.Backgrounded>);

};

export default Footer;
